<template>
    <div>
      <div v-if="isLoading">
        <base-spinner></base-spinner>
      </div>
      <div v-else class="card container special mt-5">

        <div class="row justify-content-center">
        <div class="card-title">
          <h1>Shop Details</h1>
        </div>
        <div
          class="col-sm-6 col-md-4 m-2"
          v-for="image in imageUrls"
          :key="image"
        >
          <img :src="image" alt="" />
        </div>
      </div>


        <div class="row">
          <div class="col-sm-6 col-md-4 py-0">
            <div class="card-body">
              <p class="card-title">
                <span class="fst-itali">{{ location }}, Kampala</span>
              </p>
              <p class="card-text">
                <span class="fw-normal">{{ BuildingName }}</span>
              </p>
              <p>
                <strong>{{ price }}$</strong> / month
              </p>
              <p>
                Contact us on
                <strong><a href="tel: +256789647271">+256 789647271</a></strong>
              </p>
              <router-link v-if="!route" :to="BookLink" class="btn btn-primary"
                >Send a request</router-link
              >
            </div>
          </div>
          <div class="col">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    // props: ["id"],
    data() {
      return {
        isLoading: false,
      };
    },
    computed: {
      price() {
        return this.selectedOffice.price;
      },
      imageUrls() {
        return this.selectedOffice.imageUrls;
      },
      location() {
        return this.selectedOffice.location;
      },
      description() {
        return this.selectedOffice.description;
      },
      BuildingName() {
        return this.selectedOffice.buildingName
      },
      BookLink() {
        return this.$route.path + "/contact";
      },
      route() {
        const checkroute = this.$route.path.slice(-7);
        // console.log(checkroute);
        return checkroute == "contact";
      },
    },
    created() {
      // first get the shop id from the route params
      const shopId = this.$route.params;
      // console.log("this is it", shopId.id, "this is the id");

      // selects the specific shop/office
      this.selectedOffice = this.$store.getters["shops/properties"].find(
        (property) => property.id === shopId.id
      );
    },
  };
  </script>
  
  <style scoped>
  div .imagePart {
    width: 100%;
    height: 20rem;
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .special {
    margin-top: 3rem;
  }
  span {
    font-weight: bold;
  }
  #email {
    font-weight: bold;
  }
  a {
    text-decoration: none;
  }
  </style>